import React from 'react';
import ReactDOM from 'react-dom';

import { withRouter } from 'react-router-dom';

import './assets/fonts/Freight.woff';
import './assets/fonts/MOD20.TTF';

import './index.css';
import './index_mobile.css';

// import balony from './assets/img/balony_rozowe.jpg';

import App from './App';
import Header from './Header';
import Footer from './Footer';
import Spacer from './blocks/Spacer';

import Hero from './blocks/Hero';
import ThreeImages from './blocks/ThreeImages';

import Home from './Home';
import Gallery from './Gallery';

import facebookIcon from './facebook.webp';
import instagramIcon from './instagram.webp';

import * as serviceWorker from './serviceWorker';
import { 
    HashRouter as Router, 
    Route,
} from 'react-router-dom';

import Collection from './lib/util';

function replaceProps (obj) {
    let recursive = function recursive (o) {
        for (let prop in o) {
            if (typeof o[prop] === 'object') {
                recursive(o[prop]);
            }
            if (o[prop + '_en']) {
                o[prop] = o[prop + '_en'];
                delete obj[prop + '_en'];
            }
        }
    }
    recursive(obj);
    return obj;
};

class Index extends React.Component {
    constructor (props) {
        super(props);
        this.db = new Collection('44308832e4d5be2cda11ed46182780');

        let emptyGallery = {
            header: null,
            description: null
        }
        this.data_PL = {
            deviceType: 'desktop',
            language: 'pl',
            menus: [],
            medias: [
                {
                    title: 'facebook',
                    url: 'https://www.facebook.com/Balonovo-272938713055349',
                    image: 'https://static.wixstatic.com/media/d3470ec8ca26475da4b228f0199b5d3d.png/v1/fill/w_40,h_40,al_c,q_85,usm_0.66_1.00_0.01/d3470ec8ca26475da4b228f0199b5d3d.webp'
                },
                {
                    title: 'instagram',
                    url: 'https://www.instagram.com/balonovo.pl/',
                    image: 'https://static.wixstatic.com/media/d7ffe259c9e54f59837481b3dd0130eb.png/v1/fill/w_40,h_40,al_c,q_85,usm_0.66_1.00_0.01/d7ffe259c9e54f59837481b3dd0130eb.webp'
                }
            ],
            galleries: [emptyGallery, emptyGallery, emptyGallery, emptyGallery, emptyGallery, emptyGallery, emptyGallery],
            home: {
                textWithHeader: { header: '', body: '', buttonText: '' },
                form: { header: '', description: '', fields: [], privacypolicy: '', buttonText: '' }
            }
        };
        this.data_EN = {};

        this.state = {
            ...this.data_PL
        };

        this.resolveDevice = this.resolveDevice.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    async componentDidMount() {
        let home = {};
        // home.topHero = await this.db.get('heroImage', 'id', 'home-main');
        // home.bottomHero = await this.db.get('heroImage', 'id', 'home-bottom');
        home.textWithHeader = await this.db.get('texts', 'name', 'about-us');
        home.form = await this.db.get('forms', 'name', 'contactForm');

        let menus = await this.db.getAll('menus');
        let galleries = await this.db.getAll('galleries');

        this.data_PL = {
            ...this.data_PL,
            home,
            menus,
            galleries,
        };

        this.setState({
            ...this.data_PL,
        });
        
        this.data_EN = replaceProps(JSON.parse(JSON.stringify(this.data_PL)));

        window.addEventListener('resize', () => {
            this.resolveDevice();
        });

        this.resolveDevice();

    }

    resolveDevice () {
        let deviceType;
        if (window.outerWidth >= 640) {
            deviceType = 'desktop';
        } else {
            deviceType = 'mobile';
        }

        if (deviceType !== this.state.deviceType) {
            this.setState({
                ...this.state,
                deviceType
            });
        }
    }

    handleLanguageChange(language) {
        if (language === 'en') {
            this.setState({
                ...this.data_PL,
                deviceType: this.state.deviceType,
                language: 'pl'
            });
        } else if (language === '' || language === 'pl') {
            this.setState({
                ...this.data_EN,
                deviceType: this.state.deviceType,
                language: 'en'
            });
        }

    }

    render () {
        return <React.Fragment>
            <div>
                <Router>
                    <App>
                        <Header 
                            language={this.state.language}
                            deviceType={this.state.deviceType}
                            medias={this.state.medias} 
                            menus={this.state.menus} 
                            galleryMenus={this.state.galleries}
                            onLanguageChange={this.handleLanguageChange} />
                        <Route exact path="/private-events">
                            <Gallery 
                                header={this.state.galleries[0].header}
                                description={<div dangerouslySetInnerHTML={{__html: this.state.galleries[0].description}}></div>}
                                buttonText={this.state.galleries[0].buttonText}
                            >
                                <ThreeImages id="eventy-prywatne-1" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-4" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-3" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-2" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-5" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-6" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-7" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-8" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-9" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-10" spaceAfter={true}/>
                                <ThreeImages id="eventy-prywatne-11" spaceAfter={true}/>
                                <Hero id="eventy-prywatne-1" />
                            </Gallery>
                        </Route>
                        <Route exact path="/corporate-events">
                            <Gallery 
                                header={this.state.galleries[1].header}
                                description={<div dangerouslySetInnerHTML={{__html: this.state.galleries[1].description}}></div>}
                                buttonText={this.state.galleries[1].buttonText}
                            >
                                <ThreeImages id="eventy-korporacyjne-1" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-14" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-2" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-6" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-5" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-7" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-10" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-8" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-3" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-4" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-9" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-11" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-12" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-13" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-15" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-16" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-17" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-18" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-19" spaceAfter={true}/>
                                <ThreeImages id="eventy-korporacyjne-20" spaceAfter={true}/>
                            </Gallery>
                        </Route>
                        <Route exact path="/love">
                            <Gallery 
                                header={this.state.galleries[2].header}
                                description={<div dangerouslySetInnerHTML={{__html: this.state.galleries[2].description}}></div>}
                                buttonText={this.state.galleries[2].buttonText}
                            >
                                <Hero id="love-1" />
                                <Spacer />
                                <ThreeImages id="love-1" spaceAfter={true} variant="left"/>
                                <ThreeImages id="love-2" spaceAfter={true}/>
                                <ThreeImages id="love-3" spaceAfter={true}/>
                                <ThreeImages id="love-4" spaceAfter={true}/>
                                <ThreeImages id="love-5" spaceAfter={true}/>
                                <ThreeImages id="love-6" spaceAfter={true}/>
                                <ThreeImages id="love-7" spaceAfter={true}/>
                                <ThreeImages id="love-8" spaceAfter={true}/>
                                <ThreeImages id="love-9" spaceAfter={true}/>
                                <ThreeImages id="love-10" spaceAfter={true}/>
                                <ThreeImages id="love-11" spaceAfter={true}/>
                                <ThreeImages id="love-12" spaceAfter={true}/>
                                <ThreeImages id="love-13" spaceAfter={true}/>
                                <ThreeImages id="love-14" spaceAfter={true}/>
                                <ThreeImages id="love-15" spaceAfter={true}/>
                            </Gallery>
                        </Route>
                        <Route exact path="/bespoke">
                            <Gallery 
                                header={this.state.galleries[3].header}
                                description={<div dangerouslySetInnerHTML={{__html: this.state.galleries[3].description}}></div>}
                                buttonText={this.state.galleries[3].buttonText}
                            >
                                {/* <ThreeImages spaceAfter={true} variant="left" /> */}
                                {/* <Hero id="personalizacja-1"/> */}
                                <Spacer />
                                <ThreeImages id="personalizacja-2" spaceAfter={true} />
                                <ThreeImages id="personalizacja-3" spaceAfter={true} />
                                <ThreeImages id="personalizacja-1" spaceAfter={true} />
                                <ThreeImages id="personalizacja-4" spaceAfter={true} />
                                <ThreeImages id="personalizacja-5" spaceAfter={true} />
                                <ThreeImages id="personalizacja-6" spaceAfter={true} />
                                <ThreeImages id="personalizacja-7" spaceAfter={true} />
                                <ThreeImages id="personalizacja-8" spaceAfter={true} />
                                {/* <ThreeImages id="personalizacja-4" spaceAfter={true} />  */}
                                {/* <Hero id="personalizacja-2" /> */}
                            </Gallery>
                        </Route>
                        <Route exact path="/packaging">
                            <Gallery 
                                header={this.state.galleries[4].header}
                                description={<div dangerouslySetInnerHTML={{__html: this.state.galleries[4].description}}></div>}
                                buttonText={this.state.galleries[4].buttonText}
                            >
                                <ThreeImages id="niespodzianka-1" spaceAfter={true} variant="left" />
                                <ThreeImages id="niespodzianka-2" spaceAfter={true} />
                                <ThreeImages id="niespodzianka-3" spaceAfter={true} />
                                <ThreeImages id="niespodzianka-4" spaceAfter={true} />
                                <ThreeImages id="niespodzianka-5" spaceAfter={true} />
                                <ThreeImages id="niespodzianka-6" spaceAfter={true} />
                                <ThreeImages id="niespodzianka-7" spaceAfter={true} />
                                <ThreeImages id="niespodzianka-8" spaceAfter={true} />
                            </Gallery>
                        </Route>
                        <Route exact path="/labx07-balonovo">
                            <Gallery 
                                header={this.state.galleries[5].header}
                                description={<div dangerouslySetInnerHTML={{__html: this.state.galleries[5].description}}></div>}
                                buttonText={this.state.galleries[5].buttonText}
                            >
                                <ThreeImages id="labx07-balonovo-1" spaceAfter={true}/>
                                <ThreeImages id="labx07-balonovo-2" spaceAfter={true}/>
                                <ThreeImages id="labx07-balonovo-3" spaceAfter={true}/>
                                <ThreeImages id="labx07-balonovo-4" spaceAfter={true}/>
                                <ThreeImages id="labx07-balonovo-5" spaceAfter={true}/>
                                <ThreeImages id="labx07-balonovo-6" spaceAfter={true}/>
                                <ThreeImages id="labx07-balonovo-7" spaceAfter={true}/>
                                <ThreeImages id="labx07-balonovo-8" spaceAfter={true}/>
                                <ThreeImages id="labx07-balonovo-9" spaceAfter={true}/>
                                <ThreeImages id="labx07-balonovo-10" spaceAfter={true}/>
                                <Hero id="labx07-balonovo-1" />
                            </Gallery>
                        </Route>
                        <Route exact path="/christmas">
                            <Gallery 
                                header={this.state.galleries[6].header}
                                description={<div dangerouslySetInnerHTML={{__html: this.state.galleries[6].description}}></div>}
                                buttonText={this.state.galleries[6].buttonText}
                            >
                                <ThreeImages id="christmas-1" spaceAfter={true}/>
                                <ThreeImages id="christmas-2" spaceAfter={true}/>
                                <ThreeImages id="christmas-3" spaceAfter={true}/>
                                <ThreeImages id="christmas-4" spaceAfter={true}/>
                                <ThreeImages id="christmas-5" spaceAfter={true}/>
                                <ThreeImages id="christmas-6" spaceAfter={true}/>
                                <ThreeImages id="christmas-7" spaceAfter={true}/>
                                <ThreeImages id="christmas-8" spaceAfter={true}/>
                                <ThreeImages id="christmas-9" spaceAfter={true}/>
                                <ThreeImages id="christmas-10" spaceAfter={true}/>

                                <Hero id="christmas-1" />
                            </Gallery>
                        </Route>
                        <Route exact path="/">
                            <Home medias={this.state.medias} data={this.state.home}/>
                        </Route>
                        <Footer />
                    </App>
                </Router>
            </div>
        </React.Fragment>
    }
}

ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
