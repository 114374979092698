import 'whatwg-fetch';

class Collection {
  constructor(token) {
    this.token = token;
  }

  get(collection, key, value, language = '') {
    return new Promise (resolve => {
      fetch(`https://balonovo.pl/cockpit/api/collections/get/${collection}?token=${this.token}`)
      .then(res => res.json())
      .then(res => {
        let result = null;
        res.entries.forEach(function (entry) {
          if (entry[key] === value) {
            result = entry;
            return;
          }
        });
        resolve(result);
      });
    });
  }

  getAll(collection) {
    return new Promise (resolve => {
      fetch(`https://balonovo.pl/cockpit/api/collections/get/${collection}?token=${this.token}`)
      .then(res => res.json())
      .then(res => {
        resolve(res.entries);
      })
    });
  }
}

export default Collection;