import React from 'react';

import Spacer from './Spacer';
import Collection from '../lib/util';

class ThreeImages extends React.Component {
  constructor (props) {
    super (props);

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      first: null,
      second: null,
      third: null
    }
  }

  componentDidMount () {
    this.db = new Collection('44308832e4d5be2cda11ed46182780');
    this.db.get('ThreeImages', 'id', this.props.id)
      .then((result) => {
        if (result) {
          this.setState({
            first: 'https://balonovo.pl/' + result.first.path ,
            second: 'https://balonovo.pl/' + result.second.path,
            third: 'https://balonovo.pl/' + result.third.path,
            variant: result.variant || 'left'
          });
        }
      });
  }

  handleClick () {

  }

  render () {
    let spaceAfter = this.props.spaceAfter === true ? <Spacer /> : "";
    let variant = this.state.variant || 'left';
    let html;

    let bigOne = <div className="b-threeImages--image b-threeImages--bigOne" style={{backgroundImage: `url(${this.state.first}`}}></div>;
    let smallerOnes = <div className={"b-threeImages--smallerOnes " + variant}>
      <div className="b-threeImages--image b-threeImages--smallImage up" style={{backgroundImage: `url(${this.state.second}`}}></div>
      <div className="b-threeImages--image b-threeImages--smallImage down" style={{backgroundImage: `url(${this.state.third}`}}></div>
    </div>

    if (variant === "left") {
      html = <React.Fragment>
        {bigOne}
        {smallerOnes}
      </React.Fragment>;
    } else {
      html = <React.Fragment>
        {smallerOnes}
        {bigOne}
      </React.Fragment>;
    }

    let final = this.state.first != null && this.state.first !== 'https://balonovo.pl/undefined' ? <React.Fragment>
    <div className="b-threeImages flex">
      {html}
    </div> 
    {spaceAfter}
    </React.Fragment> : null;

    return final;
  }
}

export default ThreeImages;