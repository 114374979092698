import React from 'react';

import Collection from '../lib/util';
import HashButton from '../blocks/ui/HashButton';

class TextWithHeader extends React.Component {
    constructor(props) {
        super(props);

        this.collection = new Collection('44308832e4d5be2cda11ed46182780');
        this.state = {
            data: {
                header: null,
                body: null
            }
        }
    }

    async componentDidMount () {
        // let data = await this.collection.get('texts', 'id', this.props.id);
        // this.setState({data});
    }

    render () {
        return <div className="b-textWithHeader flex" id={this.props.name}>
            <div className="b-textWithHeader--header text-center">{this.props.header}</div>
            <div className="b-textWithHeader--text text-center">
                <div dangerouslySetInnerHTML={{__html: this.props.body}}></div>
                <HashButton className="b-galleryPage--contactButton" text={this.props.buttonText} 
      to="/#contact"/>
            </div>
        </div>
    }
}

export default TextWithHeader;