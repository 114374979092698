import React from 'react';

import Spacer from '../blocks/Spacer';

import Input from '../blocks/ui/Input';
import CalendarInput from '../blocks/ui/CalendarInput';
import Textarea from '../blocks/ui/Textarea';
import Button from '../blocks/ui/Button';
import SocialMedia from '../blocks/SocialMedia';

import 'whatwg-fetch';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.state.fields = {};
    
    this.state.sent = false;
    this.state.valid = false;

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    let fields = this.state.fields;
    fields.date = document.querySelector('.b-calendarInput .b-textfield--input').value;

    console.log(fields);
    if ((!fields['name'] || fields['name'].trim() === '') ||
    (!fields['email'] || fields['email'].trim() === '') ||
    (!fields['phone'] || fields['phone'].trim() === '') ||
    (!fields['location'] || fields['location'].trim() === '') ||
    (!fields['time'] || fields['time'].trim() === '') ||
    (!fields['date'] || fields['date'].trim() === '') ||
    (!fields['message'] || fields['message'].trim() === '')) {
      window.alert('Proszę uzupełnić wszystkie pola.');
      return;
    }

    fetch('/cockpit/api/forms/submit/contact?token=44308832e4d5be2cda11ed46182780', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          form: {
              ...fields
          }
      })
    })
    .then(entry => entry.json())
    .then(entry => {
      fetch('/sendform.php', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            form: {
                ...fields
            }
        })
      });

      this.setState({ sent: true, fields: {} });
    });

    event.preventDefault();
    return false;
  }

  handleChange(event, field) {
    let fields = this.state.fields;
    this.setState({
      fields: {
        ...fields,
        [field]: event.target.value
      }
    });
  }

  render () {
    let fields = this.props.fields.fields;
    let fieldsElements = [];
    if (fields) {
      fieldsElements = fields.map((field, index) => {
        let name = Object.keys(field)[0];
        let data = field[name];
        
        // if (this.state.fields) {
        //   if (this.state.fields[name]) {
        //     if (this.state.fields[name].value) {
        //       value = this.state.fields[name].value;
        //     }
        //   }
        // }

        if (data.type === 'textarea') {
          return <Textarea disabled={this.state.sent} placeholder={data.placeholder} key={name}
            label={data.label} required={data.required} rows="10" resizable={false} 
             onChange={(e) => this.handleChange(e, name)} />
        } else if (data.type === 'date') {
          return <CalendarInput id="calendar-input" disabled={this.state.sent} label={data.label} key={name}
            required={data.required}  
            onChange={(e) => this.handleChange(e, name)} />
        } else {
          return <Input disabled={this.state.sent} label={data.label} key={name}
            required={data.required} 
            onChange={(e) => this.handleChange(e, name)} />
        }
      });
    }

    return <div className="b-contactForm" id={this.props.id}>
      <div className="b-contactForm--textContainer">
        <div className="b-contactForm--header text-center">{this.props.header}</div>
        <div className="b-contactForm--text text-center" dangerouslySetInnerHTML={{__html: this.props.text}}></div>
      </div>

      <Spacer margin="2"/>
      <SocialMedia className="text-center" medias={this.props.medias}/>
      <Spacer margin="1"/>

      <form className="b-contactForm--form" onSubmit={this.handleSubmit} noValidate>
        {fieldsElements}
        <span className="b-contactForm--privacypolicy">
          {this.props.privacypolicy}
        </span>
        <Spacer margin="2.5"/>

        {this.state.sent === false ? <Button text={this.props.buttonText} align="center" 
        onClick={this.handleSubmit}/> : <p className="b-contactForm--thankyou">Dziękujemy za wiadomość.</p>}
      </form>
    </div>
  }
}

export default ContactForm;