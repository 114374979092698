import React from 'react';

class Input extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      validClassName: 'untouched'
    }
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur(event) {
    let validClassName = this.state.validClassName;
    if (this.props.required) {
      let text = event.target.value.trim();
      if (text.length > 0) {
        validClassName = "valid"
      } else {
        validClassName = "invalid";
      }
    } else {
      validClassName = "valid";
    }
    this.setState({
      validClassName
    });
  }

  render () {
    return <div className={"b-textfield " + 'b-textfield--' + this.state.validClassName}>
      <label>
        <div className="b-textfield--labelText">{this.props.label} {this.props.required === true ? ' *' : ''}</div>
        <input disabled={this.props.disabled} onChange={this.props.onChange} onBlur={this.handleBlur} value={this.props.value}
        className="b-textfield--input" type="text" required={this.props.required}/>
      </label>
    </div>
  }
}

export default Input;