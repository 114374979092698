import React from 'react';

import Calendar from 'react-calendar';

class CalendarInput extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      validClassName: 'untouched',
      date: null,
      calendarOpen: false
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount () {
    window.addEventListener('click', () => {
      this.setState({calendarOpen: false});
    });
  }

  handleBlur(event) {
    let validClassName = this.state.validClassName;
    if (this.props.required) {
      let text = event.target.value.trim();
      if (text.length > 0) {
        validClassName = "valid"
      } else {
        validClassName = "invalid";
      }
    } else {
      validClassName = "valid";
    }
    this.setState({
      validClassName,
      calendarOpen: false
    });
  }

  handleClick (e) {
    e.stopPropagation();
  }

  handleFocus() {
    this.setState({
      calendarOpen: true
    });
  }

  handleChange = (date) => {
    this.setState({
      date,
      calendarOpen: false
    });
  }

  render () {
    return <div className={"b-textfield b-calendarInput " + 'b-textfield--' + this.state.validClassName} onClick={this.handleClick} >
      <label>
        <div className="b-textfield--labelText">{this.props.label} {this.props.required === true ? ' *' : ''}</div>
        <input value={this.state.date ? this.state.date.toLocaleDateString() : ''} onFocus={this.handleFocus} disabled={this.props.disabled} onChange={this.props.onChange} 
        className="b-textfield--input" type="text" required={this.props.required}/>
        <div style={{position: 'absolute', display: this.state.calendarOpen === true ? 'block' : 'none'}}>
          <Calendar
            minDate={new Date()}
            onChange={this.handleChange}
            value={this.state.date}
            locale="pl"
          />
        </div>
      </label>
    </div>
  }
}

export default CalendarInput;