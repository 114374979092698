import React from 'react';

class Button extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    return <div className={'b-buttonContainer ' + 'b-buttonContainer--' + (this.props.align || 'center') + ` ${this.props.className}`}>
      <div className={'b-button ' + 'b-button--' + (this.props.variant || 'outlined')}
        onClick={this.props.onClick}>{this.props.text}</div>
    </div>
  }
}

export default Button;