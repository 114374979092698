import React from 'react';

import Collection from '../lib/util';

class Hero extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            title: '',
            image: null
        }
    }

    componentDidMount () {
        this.db = new Collection('44308832e4d5be2cda11ed46182780');
        this.db.get('heroImage', 'id', this.props.id)
            .then((result) => {
                if (result) {
                    this.setState({
                        title: result.title,
                        image: 'https://balonovo.pl/' + result.photo.path
                    });
                }
            });
    }

    render () {
        let final = this.state.image != null && this.state.image !== 'https://balonovo.pl/undefined' ? <div id={`hero-${this.props.id}`} className="b-hero after-header flex parallax" style={{
            backgroundImage: `url(${this.state.image})`
        }}>
            <div className="b-hero--text"><h1>{this.state.title}</h1></div>
        </div> : null;
        return final;
    }
}

export default Hero;