import React from 'react';

import {
  Link
} from 'react-router-dom';

class HashButton extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    return <div className={'b-buttonContainer ' + 'b-buttonContainer--' + (this.props.align || 'center') + ` ${this.props.className}`}>
      <Link to={this.props.to}>
        <div className={'b-button b-hashbutton ' + 'b-button--' + (this.props.variant || 'outlined')}
          onClick={this.props.onClick}>{this.props.text}</div>
      </Link>
    </div>
  }
}

export default HashButton;