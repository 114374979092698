import React from 'react';

import {
    NavLink as Link,
    Link as StaticLink
} from 'react-router-dom';

import {
    HashLink
} from 'react-router-hash-link';

import SocialMedia from './blocks/SocialMedia';

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.menuTimer = null;

        this.state = {
            menuOpen: false
        }

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleGalleryClick = this.handleGalleryClick.bind(this);
    }

    handleMouseEnter () {
        clearTimeout(this.menuTimer);
        this.menuTimer = null;
        this.setState({
            menuOpen: true
        });
    }
    handleMouseLeave () {
        this.menuTimer = setTimeout(() => {
            if (this.menuTimer) {
                this.setState({
                    menuOpen: false
                });
            }
            this.menuTimer = null;
        }, 1000);
    }
    handleGalleryClick () {
        if (this.props.deviceType === 'desktop') {
            // document.documentElement.scrollIntoView({
            //     behavior: 'smooth',
            //     block: 'start'
            // });
            window.scrollTo(0, 0);
        } else if (this.props.deviceType === 'mobile') {
            this.setState({
                menuOpen: !this.state.menuOpen
            });
        }
    }

    render () {
        let menus = this.props.menus.map(menu => {
            if (menu.url) {
                if (menu.url[0] === '#') {
                    return <Link strict exact to={'/' + menu.url} key={menu.url}
                        className="b-header--menuItem b-link">
                            {menu.header}
                    </Link>
                } else {
                    return <Link strict exact to={menu.url} key={menu.url}
                        activeClassName="b-header--activeLink" 
                        className="b-header--menuItem b-link">
                            {menu.header}
                    </Link>
                }   
            } else if (menu.name === 'Galeria') {
                let galleryMenus = this.props.galleryMenus.map((galleryMenu) => {
                    return <li className="b-dropmenu--item b-link">
                        <Link exact activeClassName="b-header--activeLink" 
                            to={`/${galleryMenu.url}`} onClick={this.handleGalleryClick}>
                        {galleryMenu.header}
                        </Link>
                    </li>
                });

                return <React.Fragment key={menu.url}>
                    <div className="b-dropmenu" onMouseLeave={this.handleMouseLeave} onMouseEnter={this.handleMouseEnter}>
                        <span className="b-dropmenu--firstItem b-link"
                            onClick={this.handleGalleryClick}>{menu.header}</span>
                        <ul style={{display: this.state.menuOpen === true ? 'block' : 'none'}}>
                            {galleryMenus}
                        </ul>
                    </div>
                </React.Fragment>
            }
        });

        let deviceType = this.props.deviceType;
        let header;

        if (deviceType === 'desktop') {
            header = <div className="b-header desktop">
                <div className="b-header--container flex" onMouseLeave={this.handleMouseLeave}>
                    <SocialMedia medias={this.props.medias}/>
                    <div className="b-header--menu flex">
                        {menus}
                    </div>
                    <div className="b-header--language" onClick={() => this.props.onLanguageChange(this.props.language)}>
                        <span>
                            {this.props.language === 'pl' ? 'English' : 'Polski'}
                        </span>
                    </div>
                </div>
            </div>
        } else if (deviceType === 'mobile') {
            header = <div className="b-header mobile">
                <div className="b-header--container flex" onMouseLeave={this.handleMouseLeave}>
                    <SocialMedia medias={this.props.medias}/>
                    <div className="b-header--menu flex">
                        {menus}
                        <div className="b-header--language" onClick={() => this.props.onLanguageChange(this.props.language)}>
                        <span>
                            {this.props.language === 'pl' ? 'English' : 'Polski'}
                        </span>
                        </div>
                    </div>
                    
                </div>
            </div>
        }

        

        return header;
    }
}

export default Header;