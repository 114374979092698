import React from 'react';

function SocialMedia (props) {
  let medias = props.medias.map(function (media) {
    let el = <div className="b-socialMedia--media" key={media.title}>
      <a className="b-socialMedia--link" target="_blank" rel="noopener noreferrer" href={media.url}>
        <img className="b-socialMedia--image" src={media.image} alt={media.title}/>
      </a>
    </div>

    return el;
  });

  return <div className={"b-socialMedia " + props.className}>
    {medias}
  </div>
}

export default SocialMedia;