import React from 'react';
import { withRouter } from 'react-router-dom';

import Smooth from 'smooth-scrolling';

function waitForDOM(el, callback) {
  if (!el.offsetTop) {
    window.requestAnimationFrame(waitForDOM);
  }else {
     callback(el);
   }
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.smooth = new Smooth({
      section: document.documentElement,
      ease: 0.1,
      native: true
    });
    this.handleRouteChange = this.handleRouteChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleRouteChange(prevProps);
    }
  }

  handleRouteChange (prevProps) {
    let location = this.props.location;
    let headerHeight = document.querySelector('.b-header').offsetHeight;

    if (location.hash) {
      let el = document.getElementById(location.hash.substring(1));
      let scroll;

      if (el) {
        if (location.hash === '#about-us') {
          scroll = el.offsetTop - (el.offsetHeight / 2) + headerHeight;
        } else {
          scroll = el.offsetTop - headerHeight;
        }
        
        let timeout = 1;
        if (prevProps.location.pathname !== location.pathname) timeout = 200;
        this.timeout = window.setTimeout(() => this.smooth.scrollTo(scroll), timeout);
        this.smooth.scrollTo(scroll);

      }
    } else {
      document.querySelector('html').style.scrollBehavior = 'initial';
      window.scrollTo(0,0);
      document.querySelector('html').style.scrollBehavior = 'smooth';
    }
  }

  render () {
    return <React.Fragment>
      {this.props.children}
    </React.Fragment>
  }
}

export default withRouter(App);