import React from 'react';
import { withRouter } from 'react-router-dom';

import Hero from './blocks/Hero';
import TextWithHeader from './blocks/TextWithHeader';
import ContactForm from './blocks/ContactForm';
import Spacer from './blocks/Spacer'
import ThreeImages from './blocks/ThreeImages';

import balonyRozowe from '../src/assets/img/balony_rozowe.jpg';
// import balonyNeon from '../src/assets/img/balony_neon.jpg';

import 'whatwg-fetch';

class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className = "Home">
      <Hero id="home-main"/>
      <Spacer />
      <TextWithHeader id="about-us" 
        name = {this.props.data.textWithHeader.name}
        header={this.props.data.textWithHeader.header} 
        body={this.props.data.textWithHeader.body}
        buttonText={this.props.data.textWithHeader.buttonText} />

      <Spacer margin="8" />

      <Hero id="home-bottom" />

      <Spacer />
      <ContactForm id="contact" header={this.props.data.form.header}
        text={this.props.data.form.description}
        fields={this.props.data.form.fields}
        medias={this.props.medias}
        privacypolicy={this.props.data.form.privacypolicy}
        buttonText={this.props.data.form.buttonText}
      />

      <Spacer />
      {/* <ThreeImages spaceAfter={true} variant="right" data={this.state.images.top1}/> */}
      {/* <ThreeImages spaceAfter={true} variant="left" data={this.state.images.top2} /> */}
    </div>
  }
}

export default withRouter(Home);