import React from 'react';

class Textarea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validClassName: 'untouched'
    }

    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur (event) {
    let validClassName = this.state.validClassName;
    if (this.props.required) {
      let text = event.target.value.trim();
      if (text.length > 0) {
        validClassName = "valid"
      } else {
        validClassName = "invalid";
      }
    } else {
      validClassName = "valid";
    }
    this.setState({
      validClassName
    });
  }

  render () {
    return <div className={"b-textarea " + 'b-textarea--' + this.state.validClassName}>
    <label>
      <div className="b-textarea--labelText">{this.props.label} {this.props.required === true ? ' *' : ''}</div>
      <textarea onBlur={this.handleBlur} value={this.props.value} disabled={this.props.disabled}
        onChange={this.props.onChange} placeholder={this.props.placeholder} 
        className={"b-textarea--input" + ' ' + (this.props.resizable === false ? 'non-resizable' : '')} 
        rows={this.props.rows || 5} type="text" required={this.props.required}/>
    </label>
  </div>
  }
}

export default Textarea;