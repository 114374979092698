import React from 'react';
import { withRouter } from 'react-router-dom';

import HashButton from './blocks/ui/HashButton';
import Spacer from './blocks/Spacer';

class Gallery extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div className="b-galleryPage">
        <div className="b-galleryPage--top">
          <h1 className="b-galleryPage--header">{this.props.header}</h1>
          <p className="b-galleryPage--description">{this.props.description}</p>
          <HashButton className="b-galleryPage--contactButton" text={this.props.buttonText} 
        to="/#contact"/>
        </div>
        <Spacer />
        <div className="b-galleryPage--content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withRouter(Gallery);
